import React from 'react'

const LazyLoad = () => {
  return (
    <div>
      <p className='h4 d-flex justify-content-center align-items-center text-dark' style={{ height: "100vh" }}>Loading...</p>
    </div>
  )
}

export default LazyLoad