export const CLIENT_FULL_NAME = 'Enter full-name here';
export const CLIENT_USER_NAME = 'Enter username here';
export const CLIENT_EMAIL = 'Enter email here';
export const EMAIL_VALIDATION = 'Please enter your valid email'
export const DELETE_SUCCESSFULLY = 'Delete Successfully'

export const CLIENT_MOBILE = 'Enter mobile here';
export const CLIENT_GROUP = 'Select group here';
export const CLIENT_STRATEGY = 'Select strategy here';
export const CLIENT_SUB_ADMIN = 'Select sub_admin here';
export const SERVICES_SERVICE_DEATAIL = 'Enter services details here';
export const SERVICES_TOKEN = 'Enter token here';
export const SERVICES_SELECT_CATEGORIES = 'Select strategy here'
export const EDIT_CLIENT_SUCCESS = 'Client Edited Successfully done'
export const ADD_CLIENT_SUCCESS = 'Client Added Successfully done'
export const STRATEGY_ERROR_MESSAGE = 'Please Check strategy here';
export const EMPTY_STRATEGY_ERROR_MESSAGE = 'Please Enter Strategy Name First';
export const MESSAGE_ERROR = 'Please enter message here';
export const MESSAGE_BROADCAST = 'Message Sended Successfully';
export const CLIENT_NOT_AVAILABLE = 'This strategy has no clients';
export const CLIENT_ADDED_TO_STRAITGY = 'Clients Added Successfully';
export const STRATGY_ADDED_SUCCESS = 'Strategy Added Successfully';
export const STRATGY_UPDATED_SUCCESS = 'Strategy Updated Successfully';
export const SERVICE_ADDED_SUCESSFULLY = 'Service Added Successfully';
export const PASSWORD = 'Please enter your password here';
export const OLD_PASSWORD = 'Please enter your old password here';
export const NEW_PASSWORD = 'Please enter your new password here';
export const CONFORM_PASSWORD = 'Please enter confirm password here';
export const PASSWORD_NOT_MATCH = 'Password not match';
export const OLD_AND_NEW_PASSWORD_NOT_MATCH = 'Old Password And New Password Are Not Match';
export const API_KEY = 'Please enter API Key';
export const API_SECRET = 'Please enter API  Secret Key';
export const APP_ID = 'Please enter API Id';
export const CLIENT_CODE = 'Please enter Client Code';
export const API_TYPE = 'Please enter API TYPE';
export const DEMAT_USERID = 'Please enter User Id';
export const CLIENT_PASSWORD = 'Please enter Password';
export const WEBURL = 'Please select weburl '
export const RANDOM_CLIENT_KEY = 'Please generate client key';
export const USERNAME_PASSWORD_ERROR = 'Incorrect Username or Password';
export const EMAIL_PASSWORD_ERROR = 'Incorrect Email or Password';
export const ENTER_OTP = 'Please Enter OTP';
export const MOBILE_LAST_DIGITS = 'Please Enter Correct Last 4 Digit Mobile No.';
export const MESSAGE_BROADCAST_DELETE = 'Message deleted successfully';
export const SMTP_UPDATE_SUCCESS = 'SMTP E-Mail Update Suceesfully...';
export const SMTP_HOST = 'Please enter smtp host';
export const SMTP_PORT = 'Please enter smtp port';
export const COMPANY_DETAIL_UPDATE_SUCCESS = 'Company Details Updated Successfully...';
export const EMPTY_GROUP_ERROR_MSG = " Please Group Name First";
export const EMPTY_GROUP_CATAGORY_ERROR_MSG = "Select At least one Service";
export const DELETE_GROUP_CONFIRM_MSG = 'Do you want to delete this group ?';

export const ADD_GROUP_SUCCESS_MSG = "Added Group Service Successfylly ";
export const SELECT_DATE_ERROR = "Please select from date";
export const SELECT_BROKER_ERROR = "Please select broker";
export const UPDATED_SUCCESS = "Update Suceesfully...";